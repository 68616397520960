<template>
	<div class="group column vertical-form basic gap40">
		<!--@4_무통장 입금-->

		<div class="group column">
			<!--@입금계좌-->
			<label class="body-1-m mb-8"><span class="txt">입금 계좌</span></label>
			<div class="form-value">
				<div class="group column">
					<div class="group row">
						<label class="form-sub-item"><span class="txt">은행명</span></label>
						<div class="">
							<span class="txt">신한은행</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-sub-item"><span class="txt">계좌번호</span></label>
						<div class="">
							<span class="txt">100-028-394118</span>
						</div>
					</div>
					<div class="group row">
						<label class="form-sub-item"><span class="txt">예금주</span></label>
						<div class="">
							<span class="txt">(주)다우기술</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="group column">
			<!--@세금계산서-->
			<label class="body-1-m mb-8"><span class="txt">세금계산서</span></label>
			<div class="form-value pt-6">
				<div class="group column gap10">
					<div class="group-chk disabled">
						<input type="checkbox" id="r1" name="radio-group" v-model="payMethodInfo.issuedBill" :value="true" />
						<label for="r1" class="label">신청</label>
					</div>
					<div class="group column">
						<AttachComponent :gatherFlag="false" attach-btn-name="사업자등록증 첨부(필수)"></AttachComponent>
					</div>
				</div>
			</div>
		</div>
		<div class="group column">
			<!--@안내문구-->
			<label class="body-1-m mb-8"><span class="txt">안내문구</span></label>
			<div class="form-value">
				<span v-if="orderType === 'NEW' || orderType === 'FRESH'" class="txt">
					1. 입금 확인 후 영업일 기준 12시간 내에 개설해드리며 주문 담당자(주문자) 연락처와 이메일로 결제 및 주문완료
					알림이 발송됩니다. <br />
					2. 전자세금계산서는 결제일 2~3일 이후에 발행됩니다.<br />
					3. 용량 및 기타 부가서비스는 개설 후 구매할 수 있으며, 만료일 기준으로 일할 계산됩니다.<br />
					4. 상품별로는 환불이 가능하나, 선택한 상품의 개수에서 일부 개수만은 환물이 불가합니다.<br />
					예) 사용자 추가 10명 구매 -> 전체 10명은 환불 가능, 일부 인원만은 환불 불가<br />
					5. 프로모션으로 도입하신 계약 기간 내 환불 시에는 할인 혜택 금액을 공제 후 환불이 진행됩니다.<br />
				</span>
				<span v-else class="txt">
					1. 서비스 추가(용량 추가, 사용자 추가, 부가서비스 추가 등)는 만료일 기준으로 일할 계산됩니다. <br />
					2. 상품 구매후 적용까지 약 20분 가량 소요됩니다. <br />
					3. 신용카드로 결제한 전표는 서비스 로그인 후, 구매내역에서 출력할 수 있습니다. <br />
					4. 상품별로는 환불이 가능하나 선택한 상품의 개수에서 일부 개수만은 환불이 불가합니다.<br />
					예) 사용자 추가 10명 구매 완료 → 전체 10명은 환불 가능, 일부 인원만은 환불 불가<br />
					5. 프로모션으로 도입하신 계약 기간 내 환불 시에는 할인 혜택 금액을 공제 후 환불이 진행됩니다.<br />
				</span>
			</div>
		</div>

		<div class="group column">
			<!--@안내동의-->
			<label class="body-1-m mb-8">
				<span class="txt">안내동의</span>
			</label>
			<div class="form-value">
				<div class="group-chk">
					<input id="infoConsent" type="checkbox" v-model="payMethodInfo.infoConsent" />
					<label for="infoConsent" class="body-1-m">위의 안내 내용에 동의합니다.</label>
				</div>
			</div>
		</div>

		<p v-html="PURCHASE_SEND_EMAIL_PHONE()" />
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
import AttachComponent from '@/components/attach/Attach';
import { PURCHASE_SEND_EMAIL_PHONE } from '@/temlplates';

export default {
	methods: {
		PURCHASE_SEND_EMAIL_PHONE() {
			return PURCHASE_SEND_EMAIL_PHONE;
		},
	},
	mixins: [validation],
	components: {
		AttachComponent,
	},
	data() {
		return {
			payMethodInfo: {
				payType: 'DEPOSIT',
				issuedBill: true, // 계산서 발행 여부
				infoConsent: false, // 안내 동의
			},
		};
	},
	props: {
		saveFlag: {
			type: Boolean,
			default: false,
		},
		orderType: {
			type: String,
			default: '',
		},
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$emit('setPayMethodInfo', this.payMethodInfo);
			}
		},
	},
};
</script>

<style></style>
